:root{
  --primary: #dd1623;
}

body{
  background: white;
}

body, html{
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
  scroll-behavior:smooth;
}

.cv-spinner{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes sp-anime {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px #ddd solid;
  border-top: 2px #dd1623 solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}

.section-title{
  font-weight: 800;
}

.rounded-circle{
  border-radius: 100%;
}

.btn:focus{
  box-shadow: 0 0 0 0.25rem #dd162340;
}

.btn.btn-primary{
  background-color: #dd1623 !important;
  border-color: #dd1623 !important;
}